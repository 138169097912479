<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách phiếu định giá'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="excelModal">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ml-3">
            <router-link to="/trade-in/add" v-show="false">
              <button v-bind:style="btnCreate" class="btn btn-primary btn-sm">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row>
          <b-col md="3">
            <div class="d-flex justify-content-start">
              <b-input
                placeholder="Tìm kiếm theo tên, mã, IMEI sản phẩm"
                v-model="searchProduct"
                append-icon="search"
                single-line
                hide-details
                class="form-control form-control-sm"
                v-on:keyup.enter="onFilter()"
              ></b-input>
            </div>
          </b-col>
          <b-col md="3">
            <b-input
              placeholder="Tìm kiếm theo tên, SĐT KH"
              v-model="searchCustomer"
              append-icon="search"
              single-line
              hide-details
              class="form-control form-control-sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="3">
            <b-input-group>
              <date-picker
                placeholder="Từ ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="dateFrom"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col md="3">
            <b-input-group>
              <date-picker
                placeholder="Tới ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="dateTo"
              ></date-picker>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col md="3">
            <multiselect
              v-model="valueStore"
              :options="optionsStore"
              :multiple="true"
              track-by="code"
              :close-on-select="false"
              @select="onSelectStore($event)"
              @remove="onRemoveStore($event)"
              :show-labels="false"
              :showNoResults="false"
              :showPointer="false"
              :custom-label="customLabelStore"
              placeholder="Chọn cửa hàng"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ valueStore.length }} cửa hàng đã chọn</span
                ></template
              >
              <template v-slot:no-result> Danh sách cửa hàng rỗng! </template>
              <span
                class="checkbox-label"
                slot="option"
                slot-scope="scope"
                @click.self="select(scope.option)"
              >
                {{ scope.option.name }}
                <input
                  class="test"
                  type="checkbox"
                  v-model="scope.option.checked"
                  @focus.prevent
                />
              </span>
              <span
                class="checkbox-label"
                style="font-size: 12px"
                slot="noResult"
              >
                Không có kết quả
              </span>
            </multiselect>
          </b-col>
          <b-col md="3">
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Trạng thái --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5 mt-4">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <b-table
          :items="items"
          :fields="fields"
          class="table-bordered table-hover col-md-12"
          :small="true"
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
          </template>
          <template v-slot:cell(stt)="row">
            <div
              class="productCode"
              @click="editItem(row.item)"
              style="cursor: pointer; color: #3699ff"
            >
              <span
                v-text="row.item.id"
                class="d-flex justify-content-center"
              ></span>
            </div>

            <p style="margin-bottom: 0; font-weight: 550">
              {{ row.item.date }}
            </p>
            <p style="margin-bottom: 0">
              {{ row.item.createdBy }}
            </p>
          </template>
          <template v-slot:cell(type)="row">
            <div style="text-align: center">
              <i
                v-if="row.item.tradeInType === 1"
                class="fa fa-recycle icon-color"
                v-b-tooltip.hover
                :title="row.item.tradeInTypeName"
              ></i>
              <i
                v-else
                class="fa fa-retweet icon-color"
                v-b-tooltip.hover
                :title="row.item.tradeInTypeName"
              ></i>
            </div>
          </template>
          <template v-slot:cell(customer)="row">
            <p style="margin-bottom: 0; font-weight: 550">
              {{ row.item.customerName }}
            </p>
            <p style="margin-bottom: 0">
              <a
                class="font-weight-bold"
                v-bind:href="getLinkDocCustomer(row.item)"
                target="_self"
              >
                {{ row.item.customerPhone }}
              </a>
            </p>
          </template>
          <template v-slot:cell(productName)="row">
            <p style="margin-bottom: 0">
              {{ row.item.productName }}
            </p>
            <p style="margin-bottom: 0" class="text-imei-no">
              IMEI: {{ row.item.imeiCode }}
            </p>
          </template>
          <template v-slot:cell(estimatePrice)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.estimatePrice)"></span>
            </div>
          </template>
          <template v-slot:cell(finalPrice)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.finalPrice)"></span>
            </div>
          </template>
          <template v-slot:cell(statusName)="row">
            <span
              v-if="row.item.status === 1"
              v-text="row.item.statusName"
              class="
                label
                font-weight-bold
                label-lg label-light-success label-inline
              "
            ></span>
            <span
              v-else
              v-text="row.item.statusName"
              class="
                label
                font-weight-bold
                label-lg label-light-info label-inline
              "
            ></span>
            <span
              v-if="row.item.stockSlipCode"
              v-text="row.item.stockSlipCode"
              style="font-size: 11px; margin-top: 2px"
            ></span>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 10px" class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="importTradeIn(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Tạo phiếu nhập máy cũ
                  </span>
                </b-dropdown-item>
                <!--<b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermission('USER_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số phiếu:
              {{ convertPrice(totalRow) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              :link-gen="linkGen"
              v-show="numberOfPage >= 2"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal ref="excel-modal" hide-footer title="Xuất excel" id="excel-modal">
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import axios from 'axios';
import { BASE_URL } from '../../../utils/constants';
import Multiselect from 'vue-multiselect';
import fileDownload from '../../../utils/file-download';
export default {
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
      },
      searchProduct: '',
      fields: [
        {
          key: 'stt',
          label: 'ID',
          sortable: false,
          tdClass: 'sttClass',
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          thClass: 'sttClass',
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          tdClass: 'dateClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'dateClass',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
          tdClass: 'storeName',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'storeName',
        },
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: false,
          tdClass: 'customerClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'customerClass',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          tdClass: 'productClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'productClass',
        },
        {
          key: 'estimatePrice',
          label: 'Giá thu dự kiến',
          sortable: false,
          tdClass: 'estimatePrice',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'estimatePrice',
        },
        {
          key: 'finalPrice',
          label: 'Giá thu cuối cùng',
          sortable: false,
          tdClass: 'finalPrice',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'finalPrice',
        },
        {
          key: 'note',
          label: 'Ghi chú',
          sortable: false,
          tdClass: 'imeiCodeClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'imeiCodeClass',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
          tdClass: 'statusClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
          thClass: 'statusClass',
        },
        {
          key: 'actions',
          label: '',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
      ],
      items: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: 1,
      totalRow: 1,
      onLoading: false,
      count: 1,
      searchCustomer: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      dateFrom: '',
      dateTo: '',
      selectedListExcel: 'all',
      optionsStore: [],
      valueStore: [],
      listStatus: [
        {
          id: null,
          name: 'Chọn trạng thái',
        },
        {
          id: 1,
          name: 'Đã báo giá',
        },
        {
          id: 2,
          name: 'Đã thu cũ',
        },
      ],
      selectedStatus: null,
      searchId: '',
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    Multiselect,
  },
  created() {
    this.fetchStore();
    //this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu định giá', route: 'trade-in' },
      { title: 'Danh sách phiếu định giá' },
    ]);
  },
  methods: {
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let fromDate = this.dateFrom
        ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
      let toDate = this.dateTo
        ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
        : null;
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      let param = {
        page: this.page,
        limit: 10,
        searchProduct: this.searchProduct ? this.searchProduct.trim() : '',
        searchCustomer: this.searchCustomer ? this.searchCustomer.trim() : '',
        fromDate: fromDate,
        toDate: toDate,
        store: listStoreId,
        selectedStatus: this.selectedStatus,
        searchId: this.searchId ? this.searchId.trim() : '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('tradeIn', paramQuery).then(({ data }) => {
        if (this.page === 1) {
          this.count = 1;
        } else {
          this.count = 1 + (this.page - 1) * 10;
        }
        this.items = [];
        this.numberOfPage = data.data.total_page;
        this.totalRow = data.data.total_row;
        data.data.data.forEach((element) => {
          let item = {
            id: element.id,
            stt: this.count,
            customerId: element.customerId,
            customerName: element.customerName,
            customerPhone: element.customerPhone,
            productName: element.productName,
            imeiCode: element.imeiCode,
            estimatePrice: element.estimationBuydingPrice,
            finalPrice: element.finalBuyingPrice,
            date: element.date,
            storeName: element.storeName,
            status: element.status,
            createdBy: element.createdBy,
            statusName: element.statusName,
            tradeInType: element.tradeInType,
            tradeInTypeName: element.tradeInTypeName,
            stockSlipCode: element.stockSlipCode,
            note: element.note,
          };
          this.items.push(item);
          this.count++;
        });
      });
      this.onLoading = false;
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-trade-in',
        query: { id: item.id },
      });
    },
    importTradeIn: function (item) {
      const { href } = this.$router.resolve({
        name: 'import-trade-in',
        query: { id: item.id },
      });
      window.open(href, '_blank');
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.post(`user/delete/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.onFilter();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa tài khoản!',
        text: 'Bạn có chắc muốn xóa tài khoản này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },

    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('USER_VIEW')) {
        count++;
      }
      if (localData.checkPermission('USER_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-trade-in',
      });
      this.fetchData();
    },
    excelModal: async function () {
      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      const fromDate = this.dateFrom
        ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
      const toDate = this.dateTo
        ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
        : null;
      const listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      const params = {
        searchProduct: this.searchProduct,
        searchCustomer: this.searchCustomer,
        fromDate: fromDate,
        toDate: toDate,
        storeIds: listStoreId,
        status: this.selectedStatus,
        searchId: this.searchId ? this.searchId.trim() : '',
      };
      const url = `${BASE_URL}tradeIn/export-excel`;
      axios
        .get(url, {
          params: {
            ...params,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch((err) => {
          if (!err.response) {
            this.makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            this.makeToastFailure(message);
          }

          this.$nprogress.done();
        });
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
        this.fetchStoreByUser();
      });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              const index = this.optionsStore.findIndex(
                (item) => item.code == element.id
              );
              if (index > -1) {
                this.optionsStore[index].checked = true;
                this.valueStore.push(this.optionsStore[index]);
              }
            }
          });
        }
        this.fetchData();
      });
    },
    getLinkDocCustomer(item) {
      return `#/customers/update-customer?id=${item.customerId}&phoneNo=${item.customerPhone}`;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 7%;
  text-align: center;
}
.dateClass {
  width: 5%;
}
.customerClass {
  width: 10%;
}
.productClass {
  width: 17%;
}
.imeiCodeClass {
  width: 15%;
}
.estimatePrice {
  width: 10%;
}
.finalPrice {
  width: 10%;
}
.storeName {
  width: 10%;
}
.satusClass {
  width: 10%;
}
.text-imei-no {
  font-size: 13px;
  font-style: italic;
  color: dodgerblue;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.productCode:hover {
  text-decoration: underline;
}
.icon-color {
  color: #464e5f;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 12px;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}
</style>
